.detailpage {
  margin: 18rem 0 20rem 0;

  @include for-tablet-portrait-up {
    margin: 25rem 0 20rem 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__back {
    @include link;
    align-self: flex-start;
    margin-bottom: $spacing;
  }

  .product {
    display: flex;
    flex-direction: column;

    width: 100%;


    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 70px;

      @include for-tablet-portrait-up {
        width: 50%;
        margin-bottom: 0px;
      }
    }

    &__data {
      width: 100%;

      @include for-tablet-portrait-up {
        width: 50%;
        flex-grow: 1;
      }
    }

    &__title-wrapper {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;

      &__title {
        @include h2;
        // margin-bottom: 10px;
      }

      input,
        select {
          margin-top: 10px;
          margin-left: 30px;
          border: 0.1rem solid $black;
          font-size: 2rem;
          padding: 0.6rem;
          width: 15rem;
          background-color: #fff;
        }
    }

    &__description {
      @include body;
      margin-bottom: 5rem;
    }

    &__features {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      margin-bottom: 3rem;

      &__title {
        margin-bottom: 20px;
        font-size: 16px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        margin-bottom: 20px;
        width: 50px;

        &__label {
          text-align: center;
          font-size: 14px;
          margin-top: 8px;
        }
      }
    }

    &__colors {
      display: flex;
      margin-bottom: 50px;

      &__color {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;

        &__preview {
          display: inline-block;
          width: 25px;
          height: 25px;
          margin-bottom: 10px;

          border-radius: 30px;
          border: 1px solid #ccc;
        }

        &__name {
          font-size: 14px;
        }
      }
    }


    // &__select {
    //   padding: 10px 20px;
    //   padding-right: 45px;

    //   font-size: 18px;
    //   font-weight: 300;

    //   margin-bottom: 50px;

    //   background-color: transparent;

    //   &__label {
    //     position: relative;

    //     &:after {
    //       content: '';
  
    //       position: absolute;
    //       display: block;
    //       z-index: -5;
  
    //       top: 50%;
    //       right: 10px;

    //       transform: translateY(-50%);
  
    //       width: 25px;
    //       height: 25px;
  
    //       // background-color: blue;
    //       background-image: url('../img/caret-down.svg');
    //       background-size: contain;
    //       background-repeat: no-repeat;
    //     }
    //   }

      
    // }

    &__button {
      @include button;

      display: inline-block;
    }

    &-buy-hire {
      margin-bottom: 30px;

      &__text {
        font-size: 16px;
        line-height: 20px;
        max-width: 50ch;
      }
    }
  }
}
