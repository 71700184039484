.newsFeed {
	margin: $vertical-spacing 0;

	&__top {
		display: flex;
		justify-content: space-between;
	}

	&__controls__button {
		border: none;
		background-color: transparent;

		&:nth-child(1) {
			opacity: .5;
			transition: $animation;

			&:hover {
				opacity: 1;
			}
		}
	}

	&__posts {
		display: flex;

		overflow-x: scroll;
		overflow-y: hidden;

		padding-bottom: 2rem;

		&::-webkit-scrollbar {
			display: none;
		}

		&__item {
			display: flex;
			flex-direction: column;

			max-width: calc(100% / 5);
			min-width: 26rem;
			margin-right: 3rem;

			&__image {
				display: block;
				width: 100%;
				height: 18rem;
				background-size: cover;
				margin-bottom: 2rem;
			}

			&__title {
				@include body;
				font-weight: $bold;
				margin-bottom: 1rem;
				flex-grow: 1;
			}

			&__preview {
				@include body;
				color: #CCC;
				margin-bottom: 2rem;
				flex-grow: 1;
			}

			&__link {
				a {
					@include link;
				}
			}

		}
	}
}