// all common ui elements (titles, body, buttons, links,...)
@mixin h2 {
  font-size: 3.5rem;
  font-weight: $bold;
  line-height: 4.5rem;
  margin-bottom: $spacing;

  @include for-tablet-portrait-up {
    font-size: 4.5rem;
    line-height: 6rem;
  }
}

@mixin h3 {
  font-size: 2rem;
  font-weight: $bold;
  line-height: 3rem;

  @include for-tablet-portrait-up {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@mixin body {
  font-size: 2rem;
  line-height: 3.3rem;
}

@mixin link {
  color: $black;
  text-decoration: none;
  border-bottom: 0.2rem solid $brand;
  transition: $animation;

  &:hover {
    color: $brand;
  }
}

@mixin small {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: $bold;
  color: $brand;
}

.wrapper {
  padding: 0 2rem;
  position: relative;

  @include for-tablet-portrait-up {
    padding: 0 $spacing2x;

    max-width: 130rem;
    margin: 0 auto;
  }
}

@mixin button {
  background-color: $brand;
  border: 0.2rem solid $brand;
  padding: 2rem;
  color: $white;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 1.8rem;

  transition: $animation;

  &:hover {
    cursor: pointer;
    background-color: transparent;
    color: $black;
  }
}
