.productpage {
  .intro {
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;

    @include for-tablet-portrait-up {
      grid-template-columns: $spacing2x 1fr $spacing2x;
    }

    @include for-tablet-landscape-up {
      grid-template-columns: $spacing2x 1fr 1fr $spacing2x;
    }

    @include for-desktop-up {
      grid-template-columns: 1fr 70rem 58rem 1fr;
      margin-bottom: 20rem;
    }

    &__left {
      grid-column: 2/3;

      padding: 20rem 0 10rem 0;

      @include for-tablet-landscape-up {
        grid-column: 2/3;
        padding: 25rem $spacing2x 0 10rem;
      }

      @include for-desktop-large {
        padding: 25rem $spacing2x 0 0;
      }

      &__small {
        @include small;
      }

      &__title {
        @include h2;
        margin-top: 1.5rem;
      }

      p {
        @include body;
        max-width: 45ch;
        margin-bottom: $spacing2x;

        strong {
          font-weight: $bold;
        }

        em {
          font-style: italic;
        }
      }

      &__button {
        @include button;
      }
    }

    &__right {
      grid-column: 1/4;

      position: relative;

      width: 100%;
      height: 40rem;

      background-size: cover;
      background-position: center;

      animation: animate-in 0.4s ease-in;

      @include for-tablet-landscape-up {
        grid-column: 3/5;
        height: 100rem;
        background-position: top;
      }

      &:before {
        content: '';

        display: none;
        position: absolute;
        width: 100%;
        height: 40rem;

        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );

        @include for-tablet-landscape-up {
          display: block;
        }
      }
    }
  }

  .products {
    margin: $vertical-spacing 0 20rem 0;

    @include for-tablet-landscape-up {
      margin: 20rem 0 30rem 0;
    }

    .hidden {
      display: none;
    }

    &__header {
      display: flex;
      flex-direction: column;

      width: 100%;

      @include for-tablet-landscape-up {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }
    }

    &__sectors {
      &__list {
        display: flex;
      }

      &__item {
        margin: 0 1rem;

        @include for-tablet-landscape-up {
          margin: 0 1.5rem;
        }

        a {
          font-size: 1.8rem;
          font-weight: $bold;
          text-decoration: none;
          color: $black;

          border-bottom: 0.3rem solid transparent;

          transition: 0.2s ease-in-out;

          cursor: pointer;

          @include for-tablet-landscape-up {
            font-size: 2rem;
          }

          &.activeSector,
          &:hover {
            border-color: $brand;
          }
        }
      }
    }

    &__items {
      display: none;
      margin: 5rem 0 $vertical-spacing 0;

      &__product {
        width: 100%;

        margin: 0 10px;
        padding: $spacing 0;
        display: flex;
        flex-direction: column;

        border-bottom: 0.1rem solid $brand;

        @include for-tablet-landscape-up {
        width: calc(100%/3 - 20px);

        }

        &__content {
          margin-bottom: 50px;
          flex-grow: 1;

          @include for-desktop-up {
            margin-left: $spacing;
            padding-right: $spacing;
          }
        }

        &__image {
          align-self: center;
          margin-bottom: $spacing;
          height: 250px;
          max-height: 250px;
        }

        &__title {
          @include h3;
          margin-bottom: 2rem;
        }

        &__text {
          @include body;
          max-width: 55ch;
          text-overflow: ellipsis;
          font-size: 16px;
          line-height: 18px;

          @include for-tablet-landscape-up {
            max-width: 30ch;
          }

          @include for-desktop-up {
            max-width: 55ch;
          }
        }

        &__button {
          display: block;

          width: 70%;
          text-align: center;

          align-self: center;

          a {
            @include button;
            display: block;
          }
        }
      }
    }
  }

  .showProducts {
    display: flex;
    flex-wrap: wrap;

    animation: fade-up 0.4s linear;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(7rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
