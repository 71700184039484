.me-clients {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 100px auto;
  max-width: 70%;
  margin-bottom: 200px;

  &__title {
    @include h2;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    // background-color: pink;

    &__item {
      margin: 40px;

      opacity: 0.8;
      filter: grayscale(100%);
      transition: 0.2s ease-in-out;

      // &:hover {
      //   opacity: 1;
      //   filter: grayscale(0%);
      // }
    }
  }
}
