.cookieBanner {
	position: fixed;
	bottom: 3rem;
	left: 3rem;

	background-color: #fff;
	padding: 2rem;

	font-size: 1.5rem;
	line-height: 2rem;
	color: #000;

	box-shadow: 2px 2px 25px 0px rgba(0,0,0,0.1);

	z-index: 999;

	animation: fade-up .5s ease-out;

	&__content {
		margin-bottom: 3rem;
	}

	&__text {
		width: 25ch;
		margin-bottom: 1rem;
	}

	&__link {
		color: #000;
		text-transform: lowercase;
	}

	&__button {
		width: 100%;
		padding: 1rem;
		font-weight: bolder;
		text-transform: uppercase;
		background-color: #000;
		color: $brand;
		border: none;

		transition: .2s ease-in-out;

		&:hover {
			color: #000;
			background-color: $brand;
		}
	}
}

@keyframes fade-up {
	0% {
		opacity: 0;
		transform: translateY(-5rem);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}