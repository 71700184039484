.contactpage {
  margin: 25rem 0;

  .wrapper {
    display: flex;
    flex-direction: column;

    @include for-desktop-up {
      flex-direction: row;
    }
  }

  .label-buy-hire {
    margin-bottom: 20px;
  }

  &__left {
    width: 100%;
    margin-bottom: $vertical-spacing;

    @include for-desktop-up {
      width: 50%;
      margin-bottom: 0;
    }

    &__small {
      @include small;
      margin-bottom: 1.5rem;
    }

    &__title {
      max-width: 20ch;
    }

    &__data {
      address {
        @include body;
        margin-bottom: $spacing;
      }

      a {
        color: $black;
        text-decoration: none;
      }
    }

    &__sidelink {
      @include link;

      display: inline-block;
      margin-bottom: 1.5rem;
    }

    &__text {
      @include body;
      max-width: 35ch;
    }
  }

  &__right {
    .form {
      position: relative;
      top: $spacing;

      &__row {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        flex-wrap: wrap;

        &--radios {
          margin-bottom: 3rem;

          .form__row__radio {
            margin-right: 1.5rem;
          }
        }

        .inputLabel {
          display: flex;
          flex-direction: column;
          margin: 0 4rem 4rem 0;
          width: 100%;

          @include for-tablet-landscape-up {
            width: auto;
          }

          .label {
            margin-bottom: 1.2rem;
          }

          input,
          select,
          textarea {
            font-family: $font-family;
            border: 0.1rem solid $black;
            font-size: 2rem;
            padding: 0.6rem;
            width: 100%;
            background-color: transparent;

            transition: $animation;

            @include for-tablet-landscape-up {
              width: 20rem;
            }

            &:focus,
            &:hover {
              outline: none;
              border-color: $brand;
            }
          }

          textarea {
            width: 100%;
          }
        }
      }

      .submit {
        @include button;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
