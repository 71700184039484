.notFound {

	.wrapper {
		margin-top: 25rem;
		margin-bottom: 25rem;
	}
	
	&__left {
		max-width: 50%;

		&__small {
			@include small;
			margin-bottom: 1.5rem;
		}

		&__title {
			@include h2;
			margin-bottom: $spacing2x;
		}

		&__button {
			@include button;
		}
	}
}