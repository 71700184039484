$animation-duration: 1.5s;

.homepage {
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    max-width: 100vw;
    height: 90vh;

    background-size: cover;

    animation: animate-in 0.5s ease-in-out;

    @include for-tablet-portrait-up {
      height: 100vh;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: #000;
      opacity: 0.3;
    }

    &__content {
      z-index: 100;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__slogan {
      font-weight: $bold;
      font-size: 4rem;
      line-height: 5rem;
      color: $white;
      text-align: center;

      max-width: 12ch;
      margin-bottom: 7rem;

      opacity: 1;
      animation: fadein1 $animation-duration ease-in-out;

      @include for-tablet-portrait-up {
        font-size: 7rem;
        line-height: 8rem;
        max-width: 18ch;
      }
    }

    &__button {
      @include button;

      // transition: 0.2s ease-in-out;
      animation: fadein2 $animation-duration ease-in-out;

      &:hover {
        background-color: transparent;
        color: $white;
      }
    }

    .scroll {
      position: absolute;
      bottom: 10rem;
      left: 50%;
      transform: translateX(-50%);

      color: $white;
      text-decoration: none;

      animation: fadein3 $animation-duration ease-in-out;

      &:after {
        content: '';

        position: absolute;
        bottom: -10rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0.2rem;
        height: 7rem;
        background-color: $white;

        transform-origin: top;

        animation: grow $animation-duration ease-in-out;
      }
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    padding: $vertical-spacing 0;

    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__left {
      max-width: 100%;
      margin-bottom: $spacing;

      @include for-tablet-landscape-up {
        max-width: 40%;
      }

      @include for-desktop-up {
        max-width: 30%;
      }

      &__title {
        @include for-tablet-landscape-up {
          font-size: 3.5rem;
          line-height: 4.5rem;
        }
      }

      p {
        @include body;
        max-width: 42ch;
      }

      &__links {
        margin-top: $spacing2x;

        a {
          @include button;

          // font-size: 2.2rem;
          // margin-right: 2.5rem;
          // font-weight: $bold;

          // @include for-desktop-up {
          //   margin: 0 4rem 4rem 0;
          // }
        }
      }
    }

    &__right {
      max-width: 100%;
      width: 100%;
      height: 40rem;

      img {
        max-width: 100%;
      }

      @include for-tablet-landscape-up {
        max-width: 50%;
        width: 50%;
        height: 71rem;
      }
    }

    &__bottom__sectors {
      text-align: center;

      &__title {
        @include h3;
        margin-bottom: 100px;
      }

      &__blocks {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__item {
          // min-width: 257px;
          margin-bottom: 80px;

          @include for-tablet-landscape-up {
            min-width: 257px;

            max-width: 30%;
          }

          h3 {
            font-weight: 900;
            margin-bottom: 20px;
          }

          p {
            @include body;
            margin-bottom: 30px;
          }

          a {
            @include link;

            font-weight: 600;
          }
        }
      }
    }
  }

  .quote {
    position: relative;
    margin: $vertical-spacing 0;
    width: 100vw;
    height: 68rem;

    background-size: cover;
    background-position: center;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: #000;
      opacity: 0.3;
    }

    .wrapper {
      &:after {
        content: '';
        display: none;
        position: absolute;
        top: 10rem;
        right: 5rem;
        width: 25rem;
        height: 25rem;

        background-image: url('../img/quotes.png');
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.7;

        @include for-tablet-portrait-up {
          display: block;
        }
      }
    }

    &__content {
      position: absolute;
      top: 35rem;
      color: $white;
      font-size: 3.5rem;
      line-height: 4.5rem;
      font-weight: $bold;
      max-width: 15ch;

      z-index: 100;

      @include for-tablet-portrait-up {
        font-size: 4.5rem;
        line-height: 5.5rem;
        max-width: 18ch;
      }

      @include for-desktop-up {
        max-width: 25ch;
      }
    }
  }

  .facts {
    margin: $vertical-spacing 0;

    .wrapper {
      display: flex;
      flex-direction: column;

      @include for-tablet-landscape-up {
        flex-direction: row;
      }
    }

    &__title {
      position: relative;
      min-width: calc(100% / 3);
    }

    &__text {
      @include body;
      max-width: 60ch;
    }
  }

  .calculator {
    margin: $vertical-spacing 0;

    &__title {
      position: relative;
      max-width: 18ch;
      z-index: 99;
    }

    &__window {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      position: relative;
      z-index: 1;
      max-width: 100%;
      margin: 0 auto;
      background-color: $white;
      padding: 3rem;

      @include for-tablet-portrait-up {
        padding: 15rem 10rem;
        max-width: 60%;
        width: 60%;
        top: -13.7rem;
        box-shadow: $box-shadow;
      }

      @include for-tablet-portrait-up {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        max-width: 80%;
        width: 80%;
      }

      @include for-desktop-up {
        max-width: 60%;
        width: 60%;
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;

      margin-bottom: $spacing2x;

      @include for-desktop-up {
        width: 50%;
        margin-bottom: 0;
      }

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;

        span {
          font-weight: $bold;
          margin-bottom: 1.2rem;
        }

        input,
        select {
          border: 0.1rem solid $black;
          font-size: 2rem;
          padding: 0.6rem;
          width: 20rem;
          background-color: #fff;
        }
      }

      &__submit {
        @include button;
        width: 15rem;
      }
    }

    &__result {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__divider {
        display: block;
        height: 0.2rem;
        width: 8rem;
        background-color: $brand;
        margin: 3rem 0 5rem;
      }

      &__small {
        @include small;
        color: #929292;
      }

      &__monthly,
      &__yearly {
        font-size: 5.5rem;
        font-weight: $bold;
        margin: 2rem 0;
        color: $black;
      }

      &__yearly {
        color: $brand;
      }
    }
  }
}

// ======================= ANIMATIONS
@keyframes animate-in {
  0% {
    height: 0;
    margin-bottom: 100vh;
  }

  100% {
    height: 100vh;
    margin-bottom: 0;
  }
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
