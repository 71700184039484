.footer {
  background-color: $black;
  color: $white;
  padding: $spacing2x $spacing;

  @include for-desktop-up {
    padding: $spacing2x;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__block {
    margin-bottom: $spacing;
    width: 100%;

    @include for-tablet-landscape-up {
      width: 50%;
    }

    @include for-desktop-up {
      width: 30%;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: $bold;
    border-bottom: 0.2rem solid $white;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  &__link {
    display: block;
    color: $white;
    text-decoration: none;
  }

  &__item {
    margin-bottom: 1.5rem;
  }
}

.switchBrand {
  background-color: $black;
  font-size: 1.4rem;
  color: $white;
  padding-bottom: $spacing;
  text-align: right;

  a {
    color: $white;
    transition: $animation;

    &:hover {
      color: #0071bc;
    }
  }
}
