.offerte {
  background-color: $bg-light;
  padding: $spacing2x 0;

  @include for-desktop-up {
    padding: $spacing2x;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 100%;

    @include for-desktop-up {
      width: 60%;
    }

    @include for-desktop-large {
      padding-left: $spacing2x;
    }

    &__small {
      @include small;
      margin-bottom: 1rem;
    }

    &__title {
      @include for-tablet-landscape-up {
        max-width: 20ch;
      }
    }

    .form {
      .label-buy-hire {
        margin: 30px 0 15px 0;
      }
      
      &__row {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;

        &__radio {
          margin-right: 20px;
        }

        .inputLabel {
          display: flex;
          flex-direction: column;
          margin: 0 4rem 4rem 0;
          width: 100%;

          @include for-tablet-landscape-up {
            width: auto;
          }

          .label {
            font-weight: $bold;
            margin-bottom: 1.2rem;
          }

          input,
          select,
          textarea {
            border: 0.1rem solid $black;
            font-size: 2rem;
            font-weight: 300;
            padding: 0.6rem;
            width: 100%;
            background-color: transparent;

            @include for-tablet-landscape-up {
              width: 20rem;
            }
          }

          textarea {
            width: 100%;
          }
        }

        .message {
          margin-top: 3.5rem;
        }
      }

      .sector {
        display: flex;
        flex-direction: column;

        p {
          display: block;

          font-weight: bold;
          margin-bottom: 15px;
        }

        .form__row__radio {
          margin-right: 20px;
        }
      }

      .submit {
        @include button;
        margin-top: $spacing;
      }
    }
  }

  &__right {
    display: none;
    position: relative;
    top: -15rem;
    width: calc(100% / 3);
    height: 65rem;
    background-size: cover;

    @include for-desktop-up {
      display: initial;
    }
  }
}
