.cookiesPage {
	margin: 25rem 0;

	&__title {
		@include h2;
	}

	&__small {
		@include small;
	}

	&__content {
		@include body;
		max-width: 65ch;

		a {
			color: $black;
		}

		h3 {
			@include h3;
		}

		p {
			margin: 3rem 0;
		}

		strong {
			font-weight: bold;
		}
	}
}