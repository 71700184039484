// general imports
@import './general/reset';
@import './general/vars';

// mixins
@import './mixins/screens';
@import './mixins/uiElements';

// component imports
@import './components/nav';
@import './components/footer';
@import './components/offerte';
@import './components/newsfeed';
@import './components/cookieBanner';
@import './components/clients';

// pages imports
@import './pages/home';
@import './pages/about';
@import './pages/products';
@import './pages/product-detail';
@import './pages/newsPost';
@import './pages/contact';
@import './pages/notFound';
@import './pages/cookies';

// general css
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 300;
  color: $black;
  overflow-x: hidden;
  //animation: fadein .5s linear;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

main {
  position: relative;
}

h2 {
  @include h2;
}

.hidden {
  display: none !important;
}

input,
textarea,
select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: light;
}

input[type="radio"] {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
}

.mobile__nav__open {
  overflow: hidden;
}
