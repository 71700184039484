.postpage {
	margin: 18rem 0 20rem 0;

	@include for-tablet-portrait-up {
		margin: 25rem 0 20rem 0;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__back {
		@include link;
		align-self: flex-start;
		margin-bottom: $spacing;
	}

	&__small {
		@include small;
		margin-bottom: 1.5rem;
	}

	&__title {
		@include h2;
		text-align: center;
	}

	&__intro {
		margin-bottom: $spacing;
		width: 100%;

		@include for-tablet-portrait-up {
			width: 70%;
		}

		p {
			@include body;
			text-align: center;
		}
	}

	&__banner {
		width: 100%;
		height: 45rem;

		background-size: cover;
		background-position: center;
		margin-bottom: $spacing2x;
	}

	&__content {
		width: 100%;

		@include for-tablet-portrait-up {
			width: 70%;
		}

		p {
			@include body;
		}

		a {
			@include link;
		}

		strong {
			font-weight: $bold;
		}

		em {
			font-style: italic;
		}

		ul, ol {
			margin: $spacing;
			list-style-type: disc;
		}

		ol {
			list-style-type: decimal;
		}

		img {
			width: 100%;
			margin: $spacing 0;
		}

		figure {
			width: 100%;
			margin: $spacing 0;

			img {
				margin: $spacing 0 1.5rem 0;
			}

			figcaption {
				color: #cccccc;
				font-style: italic;
			}
		}
	}
}