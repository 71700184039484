.nav {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &__wrapper {
    max-width: 110rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 7rem 2rem 2rem 2rem;

    transition: $animation;

    @include for-tablet-portrait-up {
      padding: 7rem $spacing2x $spacing2x $spacing2x;
    }

    @include for-desktop-up {
      padding: 7rem 0 2rem;
    }

    @include for-desktop-large {
      max-width: $max-width;
    }

    &.smallNav {
      padding: 3rem 2rem;

      @include for-tablet-portrait-up {
        padding: 1.5rem $spacing2x;
      }
    }
  }

  &__burger {
    @include for-tablet-landscape-up {
      display: none;
    }
  }

  &__center,
  &__right {
    display: none;

    @include for-tablet-landscape-up {
      display: flex;
    }
  }

  &__center.open,
  &__right.open {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    li {
      margin-bottom: 2rem;
    }
  }

  &__right.open {
    top: 65%;
  }

  &__link {
    text-decoration: none;
    transition: $animation;
    color: $black;

    border-bottom: 0.2rem solid transparent;

    width: 100%;

    &:hover,
    &--active {
      border-bottom: 0.2rem solid $brand;
    }

    &--center {
      font-size: 3rem;
      font-weight: $bold;

      @include for-tablet-landscape-up {
        margin-right: 4rem;
        font-size: 2rem;
        font-weight: 500;
      }
    }

    &--right {
      border: 0.3rem solid $black;
      padding: 1rem 1.5rem;

      text-align: center;

      font-size: 2.2rem;
      font-weight: $bold;

      @include for-tablet-landscape-up {
        font-weight: 500;
        border-width: 0.2rem;
      }

      &:hover {
        border: 0.2rem solid $brand;
      }
    }
  }

  &.light {
    a {
      color: $white;

      &:hover {
        border-color: $brand;
      }
    }

    .nav__link--right {
      border-color: $white;
      background-color: $white;
      color: $black;
    }
  }

  &.open {
    display: block;
    background-color: $white;
    height: 100vh;

    a {
      color: $black;
      border-color: $black;
    }
  }

  &.backgroundNav {
    background-color: rgba($color: $white, $alpha: 0.97);
    box-shadow: $box-shadow;

    a {
      color: $black;

      &:hover {
        border-color: $brand;
      }
    }

    .nav__link--right {
      border-color: $black;
    }
  }
}
