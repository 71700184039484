// colors
$black: #252525;
$white: #FFFFFF;
$brand: #6ECC6E;
$bg-light: #EEF9EF;

// typo
$font-family: 'Roboto', Arial, Helvetica, sans-serif;
$font-size: 2rem;
$bold: bold;

// layout
$max-width: 130rem;
$spacing: 5rem;
$spacing2x: 10rem;
$vertical-spacing: 15rem;

$width-1: calc(1300 * 0.3);

// UI
$animation: .2s ease-in-out;
$nav-height: 15rem;
$box-shadow: 2px 2px 25px 0px rgba(0,0,0,0.1);