.aboutpage {
  .intro {
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;

    @include for-tablet-portrait-up {
      grid-template-columns: $spacing2x 1fr $spacing2x;
    }

    @include for-tablet-landscape-up {
      grid-template-columns: $spacing2x 1fr 1fr $spacing2x;
    }

    @include for-desktop-up {
      grid-template-columns: 1fr 60rem 48rem 1fr;
      margin-bottom: 20rem;
    }

    @include for-desktop-large {
      grid-template-columns: 1fr 70rem 58rem 1fr;
    }

    &__left {
      grid-column: 2/3;

      padding: 20rem 0 10rem 0;

      @include for-tablet-landscape-up {
        grid-column: 2/3;
        padding: 25rem $spacing2x 0 0;
      }

      &__small {
        @include small;
      }

      &__title {
        @include h2;
        margin-top: 1.5rem;
      }

      &__item {
        padding: 4rem 0;
        background-color: $white;
        transition: $animation;

        margin-bottom: 2rem;

        @include for-tablet-landscape-up {
          padding: 4rem $spacing;
        }

        &:hover {
          box-shadow: $box-shadow;

          .intro__left__item__link {
            color: $brand;
          }
        }

        &__title {
          @include h3;
          margin-bottom: 1rem;
        }

        &__text {
          @include body;
          margin-bottom: 2rem;
        }

        &__link {
          @include link;
          font-weight: $bold;
        }
      }
    }

    &__right {
      grid-column: 1/4;

      position: relative;

      width: 100%;
      height: 40rem;

      background-size: cover;
      background-position: center;

      animation: animate-in 0.4s ease-in;

      @include for-tablet-landscape-up {
        grid-column: 3/5;
        height: 100rem;
        background-position: top;
      }

      &:before {
        content: '';

        display: none;
        position: absolute;
        width: 100%;
        height: 40rem;

        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );

        @include for-tablet-landscape-up {
          display: block;
        }
      }
    }
  }

  .why {
    margin: $vertical-spacing 0 40rem 0;

    .wrapper {
      display: flex;
      flex-direction: column;
    }

    &__titles {
      text-align: center;

      &__small {
        @include small;
      }

      &__big {
        @include h2;
      }
    }

    &__button {
      position: relative;
      top: 10rem;
      align-self: center;

      @include for-tablet-landscape-up {
        align-self: flex-end;
      }

      a {
        @include button;
      }
    }

    &__points {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include for-tablet-landscape-up {
        flex-direction: row;
      }

      &__item {
        position: relative;
        width: 100%;
        margin-bottom: 25rem;

        @include for-tablet-landscape-up {
          width: 50%;
          margin-bottom: 0;

          &:nth-child(2) {
            top: $spacing2x;
          }

          &:nth-child(3) {
            top: 180px;
            left: 15rem;
          }
        }

        &__number {
          color: $brand;
          font-size: 15rem;
          font-weight: bold;
          opacity: 0.3;

          @include for-tablet-landscape-up {
            font-size: 25rem;
          }
        }

        &__content {
          position: absolute;
          top: 45%;
          left: 22%;
        }

        &__title {
          @include h3;
          margin-bottom: 1.5rem;
        }

        &__text {
          @include body;
          max-width: 40ch;
        }
      }
    }
  }

  .content {
    margin: $vertical-spacing 0 30rem 0;
    text-align: center;

    .wrapper {
      display: flex;
      flex-direction: column;
    }

    &__text {
      @include body;
      margin: 0 auto;

      @include for-desktop-up {
        max-width: 65ch;
      }

      p {
        margin-bottom: 2rem;
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }
    }
  }
}

// =============================== ANIMATIONS
@keyframes animate-in {
  0% {
    height: 0;
    margin-bottom: 100rem;
  }

  100% {
    height: 100rem;
    margin-bottom: 0;
  }
}
